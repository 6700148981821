export const occupationOptions = [
  {
    value: 'Advertising',
    label: 'Advertising',
  },
  {
    value: 'Agri/Horticulture',
    label: 'Agri/Horticulture',
  },
  {
    value: 'Armed Services',
    label: 'Armed Services',
  },
  {
    value: 'Banking/Finance',
    label: 'Banking/Finance',
  },
  {
    value: 'Civil Service',
    label: 'Civil Service',
  },
  {
    value: 'Cleaning/Laundry',
    label: 'Cleaning/Laundry',
  },
  {
    value: 'Construction',
    label: 'Construction',
  },
  {
    value: 'Education',
    label: 'Education',
  },
  {
    value: 'Emergency Services',
    label: 'Emergency Services',
  },
  {
    value: 'Home Duties',
    label: 'Home Duties',
  },
  {
    value: 'Hospital',
    label: 'Hospital',
  },
  {
    value: 'Hotel',
    label: 'Hotel',
  },
  {
    value: 'Industry Services',
    label: 'Industry Services',
  },
  {
    value: 'Insurance',
    label: 'Insurance',
  },
  {
    value: 'IT',
    label: 'IT',
  },
  {
    value: "Leisure/Ent'tainmnt",
    label: "Leisure/Ent'tainmnt",
  },
  {
    value: 'Manufacturing',
    label: 'Manufacturing',
  },
  {
    value: 'Media',
    label: 'Media',
  },
  {
    value: 'Mining',
    label: 'Mining',
  },
  {
    value: "Other Cat'gr",
    label: "Other Cat'gr",
  },
  {
    value: 'Pensioner',
    label: 'Pensioner',
  },
  {
    value: 'Property/Business',
    label: 'Property/Business',
  },
  {
    value: 'Restaurant',
    label: 'Restaurant',
  },
  {
    value: 'Retail/Wholesale',
    label: 'Retail/Wholesale',
  },
  {
    value: 'Retired',
    label: 'Retired',
  },
  {
    value: 'Sciences',
    label: 'Sciences',
  },
  {
    value: 'Student',
    label: 'Student',
  },
  {
    value: 'Transport',
    label: 'Transport',
  },
  {
    value: 'Unemployed',
    label: 'Unemployed',
  },
];
