import React, {useEffect, useReducer} from 'react';
import {Container} from 'basis';
import queryString from 'query-string';
import {
  initialSoftQuoteState,
  PAGES,
  personalLoanSoftQuoteReducer
} from '@/components/PersonalLoanSoftQuote/pages-au/PersonalLoanSoftQuoteReducer';
import {SoftQuoteLayout} from '@/components/PersonalLoanSoftQuote/SoftQuoteLayout';
import {AboutYou} from '@/components/PersonalLoanSoftQuote/pages-au/PersonalLoanSoftQuoteAboutYou';
import {Employment} from '@/components/PersonalLoanSoftQuote/pages-au/PersonalLoanSoftQuoteEmployment';
import {YourQuote} from '@/components/PersonalLoanSoftQuote/pages-au/PersonalLoanSoftQuoteYourQuote';
import {TimeoutModalHandler} from '@/components/TimeoutModal/TimeoutModal';

const queryParams = (typeof window !== 'undefined') && queryString.parse(location.search);
const querySubType = queryParams && queryParams.subType;
const isBetterStart = (querySubType === 'betterStart');
const queryStringDefaultMapping = {
  // either `amount` or `loanAmount` can be used in the query string to set the default loan amount.
  // Note that in theory a javascript objeect's members have no order, and could be parsed in an arbitrary oder below, but in testing we found loanAmount to take precedence over amount, but please provide one of loan and loanAmount, not both.
  amount:'loanAmount',
  loanAmount: 'loanAmount',
  purpose: 'purpose',
  otherPurpose: 'otherPurpose',
  // please provide zero or one of either period or repaymentPeriod.
  period: 'repaymentPeriod',
  repaymentPeriod: 'repaymentPeriod',
  brokerFee: 'brokerFee',
  partnerName: 'partnerName',

}
const defaults = {};
if (queryParams) {
  for (const queryStringKey in queryStringDefaultMapping) {
    const defaultValueKey = queryStringDefaultMapping[queryStringKey];
    if (typeof queryParams[queryStringKey] === 'string') {
      defaults[defaultValueKey] = queryParams[queryStringKey];
    }
  }
}
const pageInfo = {
  [PAGES.ABOUT_YOU]: {
    heading: 'About You',
    screenComponent: AboutYou,
  },
  [PAGES.EMPLOYMENT]: {
    heading: 'Employment',
    screenComponent: Employment
  },
  [PAGES.YOUR_QUOTE]: {
    heading: 'Your Quote',
    screenComponent: YourQuote
  }
};

function SoftQuoteScreen({ screenComponent: ScreenComponent, state, dispatch }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [ScreenComponent]);

  return (
    <Container>
      <ScreenComponent
        state={state}
        dispatch={dispatch}
        isBetterStart={isBetterStart}
        defaults={defaults}
      />
      <TimeoutModalHandler />
    </Container>
  );
}

function PersonalLoanSoftQuote({props}) {
  const [state, dispatch] = useReducer(personalLoanSoftQuoteReducer, initialSoftQuoteState);
  const {page} = state;
  const {
    screenComponent,
  } = pageInfo[page];

  return (
    <SoftQuoteLayout props={props}>
      <SoftQuoteScreen
        screenComponent={screenComponent}
        state={state}
        dispatch={dispatch}
      />
    </SoftQuoteLayout>
  );
}

export {PersonalLoanSoftQuote};
