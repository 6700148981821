import React from 'react';
import {
  Input,
  RadioGroup,
  Select,
} from 'basis';
import { auStateOptions } from 'address-lookup/lib/siteContent/auStateList';
import {
  // NOTE we no NOT import the auDriverLicenceValidation function here. As this component can in future be used by GEM channel, we pass the validation function in as a prop.
} from '@/components/PersonalLoanSoftQuote/pages-au/PersonalLoanSoftQuoteReducer';
import {
  CHANNEL
} from '@/components/PersonalLoanSoftQuote/constants/softQuoteConstants';
import { HasLicenceOptions, hasDriversLicenceOptions } from '../constants/driversLicence';
import { validateSelect } from '@/components/PersonalLoanSoftQuote/validator/addressValidator';

export function DriversLicenceInput({ formValues, channel, driverLicenceValidation }) {
  return (
    <>
      <RadioGroup
        name="hasDriversLicence"
        label={`${channel===CHANNEL.BROKER?'Does your client':'Do you'} have an Australian Driver's Licence?`}
        options={hasDriversLicenceOptions}
        helpText={`We use this information to identify you${channel === CHANNEL.BROKER?'r client':''}`+
        ` so we can give ${channel === CHANNEL.BROKER?'them':'you'} a more accuracte rate, sooner`}
        validate={validateSelect(`Select an option`)}
      />
      {formValues.hasDriversLicence === HasLicenceOptions.yes && 
        <Select
          name="licenceState"
          label="State of issue"
          placeholder="Please select"
          options={auStateOptions}
          validate = {validateSelect(`Select a state`)}
        />
      }
      {(formValues.hasDriversLicence === HasLicenceOptions.yes) && formValues.licenceState && <>
        <Input
          name="licenceNumber"
          label="Licence number"
          testId="licence-number"
          validate={
            !formValues.hasNoDriverLicence &&
            driverLicenceValidation(
              formValues.hasNoDriverLicence
            )
          }
        />
        <img
            alt={formValues.licenceState + ' Drivers Licence'}
            src={require(`../../../images/licences/${formValues.licenceState}.svg`)} // eslint-disable-line import/no-dynamic-require
          />
        </>}
    </>
  );
}