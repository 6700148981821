import React from 'react';
import {PersonalLoanSoftQuote} from '@/components/PersonalLoanSoftQuote/pages-au/PersonalLoanSoftQuote';
import Metadata from '@/components/Metadata/Metadata';
import { SOFTQUOTE_METADATA } from '@/components/Metadata/Metadata.constants';
import {DatadogAppender} from '@/components/PersonalLoanSoftQuote/DatadogAppender';

const SoftQuote = (props) => {
  return (
    <>
      <Metadata {...SOFTQUOTE_METADATA} />
      <DatadogAppender/>
      <PersonalLoanSoftQuote props = {props}/>
    </>
  );
};
export default SoftQuote;
